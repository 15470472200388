.news-letter-signup {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 50px 0;
}

/*.news-letter-signup:before {*/
/*    content: ' ';*/
/*    display: block;*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    opacity: 0.1;*/
/*    background-image: url("/public/static/images/banner.png");*/
/*    background-repeat: no-repeat;*/
/*    background-position: 50% 0;*/
/*    background-size: cover;*/
/*}*/

.news-letter-signup__header {
    margin-bottom: 75px;
    z-index: 1;
    font-size: 40px;
    font-weight: 600;
    max-width: 1000px;
    text-align: center;
    padding: 0 10px;
}

@media screen and (max-width: 700px) {
    .news-letter-signup__header {
        font-size: 30px;
        margin-bottom: 50px;
    }
}