.page__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.page__content--padded {
    padding: 10px;
}

.page__content:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("/public/static/images/banner.png");
    background-repeat: repeat;
    background-position: 50% 0;
}
