.event-info {
    padding: 0 8px 8px;
    max-width: 300px;
}

.event-info > a {
    text-decoration: none;
    color: #000000;
}

.event-info:not(:last-child) {
    border-bottom: 1px solid #c7c7c7;
}

.event-info__title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.event-info__image {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.event-info__image > img {
    max-width: 300px !important;
    max-height: 230px !important;
    width: 100%;
    border-radius: 6px;
}

.event-info__data {
    max-width: 300px;
    margin-top: 20px;
}

.event-info__description {
    color: #747C8A;
    font-size: 15px;
    font-weight: 500;
}

.event-info__tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
}

.event-info__tags > * {
    margin-right: 5px;
    margin-top: 10px;
}