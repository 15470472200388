.popup-details-prices {
    width: 100%;
    margin: 25px 0;
}

.popup-details-prices__tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.popup-details-prices__tags > .item-tag:not(:last-child) {
    margin-right: 10px;
    margin-bottom: 10px;
}

.popup-details-prices__vat {
    width: 100%;
    text-align: center;
    color: #747C8A;
    font-size: 12px;
}