.popup-search-list-coming-soon-item {
    padding: 16px;
    max-width: 250px;
    width: 100%;
    border: 1px solid #c7c7c7;
    box-shadow: #c7c7c7 0 0 5px 0;
    border-radius: 12px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    z-index: 1;
    background-color: #FFFFFF;
}

.popup-search-list-coming-soon-item__property {
    margin: 0 0 15px;
    font-size: 20px;
    color: #000000;
    display: flex;
    align-items: center;
}

.popup-search-list-coming-soon-item__property > svg {
    margin-right: 4px;
}

.popup-search-list-coming-soon-item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
}

.popup-search-list-coming-soon-item__image > img {
    max-height: 175px;
    max-width: 100%;
    border-radius: 8px;
}

.popup-search-list-coming-soon-item__text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 600;
    color: #00887a;
    padding: 30px 0;
}

.popup-search-list-coming-soon-item__text > svg {
    margin-bottom:20px;
}


@media screen and (max-width: 575px) {
    .popup-search-list-coming-soon-item {
        padding: 16px;
        max-width: 100%;
        margin: 0;
        border: none;
        border-bottom: 1px solid #c7c7c7;
        box-shadow: none;
        border-radius: 0;
        width: auto;
    }

    .popup-search-list-coming-soon-item__text {
        padding: 30px 0;
    }
}

