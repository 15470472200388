.popup-info-container {

}

.popup-info {
    padding: 8px;
}

.popup-info > a {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.popup-info:not(:last-child) {
    border-bottom: 1px solid #c7c7c7;
}

.popup-info__image {
    max-width: 70px !important;
    max-height: 70px !important;
    border-radius: 6px;
    margin-right: 10px;
}

.popup-info__data {
    max-width: 300px;
}

.popup-info__title {
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.popup-info__title > .item-tag {
    margin-left: 5px;
}

.popup-info__description {
    margin-top: 8px;
    color: #747C8A;
    font-weight: 500;
}

.popup-info__tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.popup-info__tags > * {
    margin-right: 5px;
    margin-top: 10px;
}