.popup-search-list-coming-soon {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.popup-search-list-divider {
    text-align: center;
}

.popup-search-list-divider > hr {
    border: 1px solid #c7c7c7;
}

@media screen and (max-width: 575px) {
    .popup-search-list-divider {
        margin: 50px 0;
    }

    .popup-search-list-divider > hr {
        border: none;
    }
}