.page-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D0D5DD;
    padding: 16px 0;
}

.page-header > a {
    margin-left: 10px;
}

.page-header__logo {
    max-width: 175px;
}

.page-header__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.page-header__navigation {
    display: flex;
    justify-content: center;
    z-index: 1;
}

.page-header__navigation > button:not(:last-child) {
    margin-right: 10px;
}

.page-header__navigation-button-content {
    display: flex;
    align-items: center;
}

.page-header__navigation-button-content--popups > svg {
    margin-right: 5px;
}

.page-header__navigation-button-content--events > svg {
    margin-left: 5px;
}

@media screen and (max-width: 1150px) {
    .page-header {
        align-items: flex-end;
    }
}

@media screen and (max-width: 750px) {
    .page-header {
        flex-direction: column;
        align-items: center;
    }

    .page-header__navigation {
        margin-top: 20px;
        order: 3;
    }

    .page-header > a {
        position: initial;
        margin-top: 20px;
    }

    .page-header__menu {
        position: initial;
        width: 100%;
        justify-content: space-between;
        margin-top: 20px;
    }

    .page-header__menu > *:last-child {
        margin-right: 10px;
    }
}