.event-details-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.event-details-container {
    background-color: #FFFFFF;
    padding: 20px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
}

.event-details {
    max-width: 700px;
    width: 100%;
    margin-top: 20px;
}

.event-details-gallery {
    max-width: 700px;
    width: 100%;
}

.event-details-gallery__image > img {
    max-height: 525px !important;
    height: 100% !important;
}

.image-gallery-thumbnails-bottom.fullscreen img {
    max-height: 100vh !important;
}

.event-details__description {
    margin-top: 25px;
    margin-bottom: 25px;
}

.event-details__apply-cta {
    position: sticky;
    bottom: 0;
    text-align: center;
    background-color: #FFFFFF;
    z-index: 1;
    padding: 10px 0;
    border-top: 2px solid #c7c7c7;
    margin-top: 0;
    color: #3538CD;
}

.event-details__apply-cta > span {
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    font-size: 20px;
    font-weight: 500;
}

@media screen and (max-width: 700px) {
    .event-details-container {
        padding: 10px;
    }
}