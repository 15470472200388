.news-letter-signup-form {
    max-width: 300px;
    width: 100%;
    text-align: center;
    padding: 35px;
    border-radius: 8px;
    box-shadow: black 0 0 5px -2px;
    background: #FFFFFF;
    z-index: 1;
}

.news-letter-signup-form > *:not(:first-child) {
    margin-top: 20px;
}
