.search-results {
    height: fit-content;
    width: 100%;
    display: flex;
    position: relative;
}

.search-results__mobile-toolbar {
    display: none;
}

.search-results__list {
    height: 100%;
    max-width: 65%;
    width: 100%;
}

.search-results__map {
    width: 100%;
    height: 100vh;
    display: flex;
    flex: 1;
    position: sticky;
    top: 0;
}

.search-results__list--loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

@media screen and (max-width: 1500px) {
    .search-results__list {
        max-width: 60%;
    }
}

@media screen and (max-width: 1200px) {
    .search-results__list {
        max-width: 65%;
    }
}

@media screen and (max-width: 1000px) {
    .search-results__mobile-toolbar {
        display: block;
        text-align: right;
        position: sticky;
        top: 0;
        background-color: #FFFFFF;
        z-index: 2;
        border-bottom: 1px solid #c7c7c7;
    }

    .search-results {
        height: 100%;
    }

    .search-results__list {
        display: none;
        max-width: 100%;
    }

    .search-results__list--open {
        display: block;
    }

    .search-results__map {
        display: none;
    }

    .search-results__map--open {
        display: flex;
        max-height: 75vh;
    }
}