.page-footer {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.page-footer > a {
    color: #000000;
}