.search-filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #FFFFFF;
}

.search-filter {
    display: flex;
    z-index: 1;
    padding: 10px;
    margin: 10px 0;
}

.search-filter__item {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 2px;
    z-index: 1;
}

.search-filter__item--small {
    width: 50%
}

.search-filter__item--button {
    width: auto;
    min-width: 100px;
}

@media screen and (max-width: 750px) {
    .search-filter {
        flex-direction: column;
        width: 100%;
        max-height: 50px;
        transition: max-height 300ms ease;
        overflow: hidden;
        max-width: 400px;
    }

    .search-filter--open {
        max-height: 210px;
    }

    .search-filter__item:not(:last-child) {
        margin-bottom: 10px;
    }

    .search-filter__item--button {
        width: 100%;
    }

    .search-filter__item--small {
        width: 100%
    }
}

@media screen and (max-width: 500px) {
    .search-filter {
        margin-top: 0;
    }
}