.error-page {
    padding: 10px;
    margin: auto;
}

.error-page-content {
    text-align: center;
}

.error-page-content > h1 {
    margin-bottom: 40px;
}