.hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 20px;
}

.hero-section-text {
    max-width: 700px;
    flex-grow: 1;
    width: 100%;
}

.hero-section-text > * {
    text-align: center;
    padding: 0 16px;
}

@media screen and (max-width: 1100px) {
    .hero-section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .hero-section-text > * {
        text-align: center;
        padding: 0 16px;
    }
}