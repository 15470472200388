.file-upload-label {
    display: flex;
    width: 100%;
}

.file-upload {
    border: 1px solid #c7c7c7;
    width: 100%;
    height: 100%;
    position: relative;
}

.file-upload--error {
    border: 1px solid #c23131 !important;
}

.file-upload > input {
    display: none;
}

.file-upload__pending {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.file-upload__logo {
    width: 100%;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.file-upload__files {
    width: 100%;
    padding: 10px;
}

.file-upload__files > .item-tag {
    margin-bottom: 10px;
    margin-right: 5px;
}

.file-upload__delete {
    cursor: pointer;
}