.popup-search-property {
    padding: 16px;
    max-width: 215px;
    width: 100%;
    border: 1px solid #c7c7c7;
    box-shadow: #c7c7c7 0 0 5px 0;
    border-radius: 12px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    z-index: 1;
    background-color: #FFFFFF;
    position: relative;
    cursor: pointer;
}

.popup-search-property > h2 {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-top: 0;
}

.popup-search-property > h2 > svg {
    margin-right: 10px;
}

.popup-search-property > img {
    max-width: 100%;
    max-height: 150px;
    height: 100%;
    width: 100%;
    border-radius: 6px;
}

.popup-search-property__data {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    margin-top: 16px;
}

.popup-search-property__data-middle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-search-property__popup-count {
    font-weight: 600;
    display: flex;
    align-items: flex-end;
}

.popup-search-property__popup-count > svg {
    margin-right: 6px;
}

.popup-search-property__location {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.popup-search-property__location > svg {
    margin-right: 6px;
}

.popup-search-property__prices {
    align-self: center;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.popup-search-property__selected {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -21px;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.popup-search-property__selected-arrow {
    background-color: rgba(0, 136, 122, 0.2);
    height: 30px;
    width: 30px;
    margin-top: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    z-index: 1;
}



@media screen and (max-width: 520px) {
    .popup-search-property {
        max-width: 100%;
    }

    .popup-search-property > img {
        max-width: 250px;
        max-height: 250px;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        align-self: center;
    }
}