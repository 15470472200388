.item-tag {
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
    height: fit-content;
}

.item-tag--standard {
    background-color: #EEF4FF;
    color: #3538CD;
}

.item-tag--success {
    color: #318c2e;
    border: 1px solid #318c2e;
    box-shadow: #318c2e 0 0 3px 0;
}

.item-tag--error {
    color: #c23131;
    border: 1px solid #c23131;
    box-shadow: #c23131 0 0 3px 0;
}

.item-tag--small {
    font-size: 12px;
    padding: 4px 6px;
    font-weight: 500;
}

.item-tag--medium {
    padding: 4px 12px;
    font-weight: 500;
    font-size: 14px;
}

.item-tag--large {
    font-weight: 600;
    padding: 5px 14px;
}
