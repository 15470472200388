.popup-search-coming-soon__marker {
    background-color: #EEF4FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 28px;
    width: 28px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
    border: 3px solid #ff971b;
}

.popup-search-coming-soon__marker--info-title {
    margin: 0;
    display: flex;
    align-items: self-end;
}

.popup-search-coming-soon__marker--info-title > svg {
    margin-right: 5px;
}
