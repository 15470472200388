.event-details-extras {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
}

.event-details-extras > * {
    margin-bottom: 15px;
    margin-right: 10px;
}

.event-details-extras img {
    max-width: 20px;
}