.popup-details-header {
    display: flex;
    justify-content: space-between;
}

.popup-details-header > .rt-Separator {
    align-self: center;
}

.popup-details-header__popup {
    flex-grow: 1;
    width: 100%;
}

.popup-details-header__property {
    flex-grow: 1;
    width: 100%;
    text-align: right;
}

.popup-details-header__title > img {
    max-width: 30px;
}

.popup-details-header__title {
    display: flex;
}

.popup-details-header__title > span {
    align-self: flex-end;
    font-weight: 500;
    font-size: 14px;
    color: #747C8A;
}

.popup-details-header__property > .popup-details-header__title {
    justify-content: flex-end;
}