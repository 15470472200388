.date-range-picker-calendar__month {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.date-range-picker-calendar__month > button:first-child {
    margin-right: 10px;
}

.date-range-picker-calendar__month > button:last-child {
    margin-left: 10px;
}

.date-range-picker {
    display: flex;
    width: 100%;
}

.date-range-picker--static {
    flex-direction: column;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
}

.date-range-picker--static.date-range-picker--error {
    border: 1px solid #c23131;
}

.date-range-picker__error-message {
    text-align: center;
    color: #c23131;
    font-weight: 500;
}

.date-range-picker-trigger {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #c7c7c7;
    width: 100%;
    padding: 0 10px;
    border-radius: 4px;
}

.date-range-picker-trigger--open {
    /*border: 1px solid #318c2e;*/
    border: 1px solid #ff971b;
}

.date-range-picker-trigger--static {
    padding: 10px;
    border: none;
    width: auto;
}

.date-range-picker-trigger__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
}

.date-range-picker-trigger__input input {
    text-align: center;
}

.date-range-picker-trigger__middle {
    color: #747C8A;
    margin: 0 10px;
}

.date-range-picker-popup {
    padding: 22px;
}

.date-range-picker-popup--static {
    padding: 5px;
}

.date-range-picker-footer {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}

.date-range-picker-footer--static {
    justify-content: center;
}