.application-form {
    position: relative;
}

.application-form__label {
    color: #747C8A;
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0 5px;
}

.application-form__label-meta {
    font-size: 14px;
    color: #747C8A;
    margin-bottom: 5px;
}

.application-form__button {
    margin-top: 25px !important;
}

.application-form__wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.application-form__section {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.application-form__categories-value {
    white-space: wrap;
}

.application-form__section:first-child {
    margin-left: 10px;
}

.Mui-selected {
    background-color: rgba(255, 151, 27, 0.3) !important;
}

.application-form__news-letter-sign-up {
    margin-top: 20px;
    text-align: center;
}

.application-form__news-letter-sign-up > p {
    font-size: 14px;
    color: #7e7e7e;
    margin-top: 0;
}

.application-form__news-letter-sign-up-text {
    font-size: 18px;
    font-weight: 600;
}

@media screen and (max-width: 600px) {
    .application-form__wrapper {
        flex-direction: column;
    }

    .application-form {
        display: flex;
        flex-direction: column;
    }

    .application-form__section:first-child {
        margin-left: 0;
    }

    .application-form__categories-menu-item {
        padding: 0 50px 0 20px !important;
    }
}