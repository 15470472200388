.event-search-list-item {
    padding: 16px;
    max-width: 250px;
    width: 100%;
    border: 1px solid #c7c7c7;
    box-shadow: #c7c7c7 0 0 5px 0;
    border-radius: 12px;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #000000;
    z-index: 1;
    background-color: #FFFFFF;
}

.event-search-list-item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 175px;
}

.event-search-list-item__image > img {
    max-height: 175px;
    max-width: 100%;
    border-radius: 8px;
}

.event-search-list-item__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    margin-top: 30px;
}

.event-search-list-item:not(:last-child) {
    border-bottom: 3px solid #D0D5DD;
}

.event-search-list-items__tags-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    margin-top: 10px;
}

.event-search-list-item__location {
    margin-top: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.event-search-list-item__location > svg {
    margin-right: 3px;
}

.event-search-list-item__tags {
    display: flex;
    margin-top: 16px;
    flex-wrap: wrap;
}

.event-search-list-item__tags > *:not(:last-child) {
    margin-right: 5px;
}

.event-search-list-item__tags > * {
    margin-bottom: 5px;
}

.event-search-list-item__location-tag {
    margin-right: 4px;
}

.event-search-list-item__title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
}

.event-search-list-item__dates {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 15px;
}

.event-search-list-item__description {
    color: #747C8A;
    font-size: 14px;
}

@media screen and (max-width: 575px) {
    .event-search-list-item {
        padding: 16px;
        max-width: 100%;
        margin: 0;
        border: none;
        border-bottom: 1px solid #c7c7c7;
        box-shadow: none;
        border-radius: 0;
    }

    .event-search-list-item__title {
        text-align: center;
        margin-bottom: 25px;
    }
}
