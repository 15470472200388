.popup-info-coming-soon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 10px;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.popup-info-coming-soon > svg {
    margin-bottom: 10px;
}