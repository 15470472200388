.event-details-categories {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.event-details-categories__required,
.event-details-categories__prohibited {
    flex-grow: 1;
    width: 100%;
}

.event-details-categories__prohibited {
    text-align: right;
}

.event-details-categories__required-tags,
.event-details-categories__prohibited-tags {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.event-details-categories__prohibited-tags {
    justify-content: flex-end;
}

.event-details-categories__required-tags > * {
    margin-right: 5px;
    margin-bottom: 5px;
}

.event-details-categories__prohibited-tags > * {
    margin-left: 5px;
    margin-bottom: 5px;
}

.event-details-categories__title {
    font-weight: 500;
    color: #747C8A;
    font-size: 16px;
    padding-bottom: 10px;
}