.property-grid-popup-item {
    display: flex;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px 15px;
    align-items: center;
    max-width: 525px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-decoration: none;
    color: #000000;
}

.property-grid-popup-item__logo {
    max-width: 150px;
    width: 100%;
    max-height: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.property-grid-popup-item__logo > img {
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.property-grid-popup-item-data {
    margin-left: 20px;
}

.property-grid-popup-item-data__header {
    font-weight: 500;
    font-size: 18px;
}

.property-grid-popup-item-data__info {
    color: #7e7e7e;
    font-size: 14px;
    margin-top: 10px;
}

.property-grid-popup-item-data__description {
}

.property-grid-popup-item-data__meta {
    display: flex;
    margin-top: 10px;
    align-items: center;
}

.property-grid-popup-item-data__price {
    margin-right: 10px;
}

.property-grid-popup-item-data__tags {
    display: flex;
    flex-wrap: wrap;
}

.property-grid-popup-item-data__tags > * {
    margin: 2px;
}

@media screen and (max-width: 520px) {
    .property-grid-popup-item-data__meta {
        flex-direction: column;
        align-items: flex-start;
    }

    .property-grid-popup-item-data__price {
        margin-bottom: 10px;
    }
}