.date-range-picker-calendar-grid {
    display: flex;
    justify-content: center;
}

.date-range-picker-calendar-grid__day {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.date-range-picker-calendar-grid__day:hover:not(.date-range-picker-calendar-grid__day--off),
.date-range-picker-calendar-grid__day--selected {
    background: rgba(0, 150, 136, 0.2);
    font-weight: 600;
}

.date-range-picker-calendar-grid__day--current {
    border: 1px solid #7e7e7e;
}

.date-range-picker-calendar-grid__day--weekend {
    color: rgba(199, 12, 12, 0.85);
}

.date-range-picker-calendar-grid__day--blocked {
    background: rgba(199, 12, 12, 0.85) !important;
    color: #FFFFFF !important;
}

.date-range-picker-calendar-grid__day--unavailable {
    /*background: #e6e6e6 !important;*/
    color: #e6e6e6 !important;
}

.date-range-picker-calendar-grid__day--weekend.date-range-picker-calendar-grid__day--blocked,
.date-range-picker-calendar-grid__day--off.date-range-picker-calendar-grid__day--blocked {
    background: rgba(199, 12, 12, 0.4);
    color: #FFFFFF;
}

.date-range-picker-calendar-grid__day--off {
    color: #e6e6e6;
}

.date-range-picker-calendar-grid__day--range {
    background: rgba(0, 150, 136, 0.2);
}

.date-range-picker-calendar-grid__day--range.date-range-picker-calendar-grid__day--off,
.date-range-picker-calendar-grid__day--selected.date-range-picker-calendar-grid__day--off {
    color: #7e7e7e;
}