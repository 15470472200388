.location-wrapper-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: #e6e6e6;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.location-wrapper-loader:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("/public/static/images/banner.png");
    background-repeat: repeat;
    background-position: 50% 0;
}