.language-selector * {
    border: none !important;
}

.language-selector__image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.language-selector__image > img {
    max-width: 25px;
    max-height: 25px;
}