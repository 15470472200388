.event-details-header {
    display: flex;
    justify-content: space-between;
}

.event-details-header > .rt-Separator {
    align-self: center;
}

.event-details-header__event {
    flex-grow: 1;
    width: 100%;
}

.event-details-header__divider {
    margin: 0 10px !important;
}

.event-details-header__property {
    flex-grow: 1;
    width: 100%;
    text-align: right;
}

.event-details-header__event > h2 {
    margin-bottom: 10px;
}

.event-details-header__title {
    display: flex;
}

.event-details-header__dates {
    font-weight: 600;
}

.event-details-header__title > span {
    align-self: flex-end;
    font-weight: 500;
    font-size: 14px;
    color: #747C8A;
}

.event-details-header__property > .event-details-header__title {
    justify-content: flex-end;
}