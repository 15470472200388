.event-search__marker {
    background-color: #EEF4FF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 28px;
    width: 28px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 50%;
    border: 3px solid #3538CD;
    color: #3538CD;
}

.event-search__map--info-title {
    margin: 0;
}