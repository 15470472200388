.popup-details-application {
    /*display: flex;*/
    /*align-items: center;*/
    /*flex-direction: column;*/
}

.popup-details-application__form {
    /*max-width: 400px;*/
    position: relative;
}

.popup-details-application__label {
    color: #747C8A;
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0 5px;
}

.popup-details-application__label-meta {
    font-size: 14px;
    color: #747C8A;
    margin-bottom: 5px;
}

.popup-details-application__button {
    margin-top: 25px !important;
}

.popup-details-application__form-wrapper {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
}

.popup-details-application__form-section {
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.popup-details-application__form-section:first-child {
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .popup-details-application__form-wrapper {
        flex-direction: column;
    }

    .popup-details-application {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .popup-details-application__form {
        max-width: 400px;
    }

    .popup-details-application__form-section:first-child {
        margin-left: 0;
    }
}